import { Hero } from "components/Hero";
import { OpeningHours } from "components/OpeningHours";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Helmet from "react-helmet";
import HeroVideoMp4 from "../../static/heroHome.mp4";
import HeroVideoWebm from "../../static/heroHome.webm";

export default function IndexPage({ location }) {
  const title = "Plush Oxford";
  const description =
    "In the heart of central Oxford, Plush is an LGBTQ+ bar and club in an exciting venue, famed for incredible nights out, an awesome atmosphere & incredible drinks offers.";

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: "en-GB" }}
        title={title}
        meta={[
          { name: "description", content: description },
          { property: "og:url", content: location.href },
          { property: "og:title", content: title },
          { property: "og:description", content: description },
          { property: "og:image", content: "" },
          { name: "facebook-domain-verification", content: "" },
        ]}
      />
      <Hero
        background={
          <video autoPlay loop muted playsInline>
            <source src={HeroVideoWebm} type="video/webm" />
            <source src={HeroVideoMp4} type="video/mp4" />
          </video>
        }
      >
        <div className="home__hero">
          <h1>Oxford's premier LGBTQ+ night club and bar!</h1>
          <h2>
            Amazing atmosphere, unbeatable parties and the best cocktails
            around!
          </h2>
        </div>
      </Hero>
      <section className="home__blocks">
        <Link to="/events">
          <StaticImage
            className="home__blocks-background"
            src="../images/heroTickets.png"
            alt=""
          />
          <div className="home__blocks-gradient" />
          <div className="home__blocks-content">Buy Tickets</div>
        </Link>
      </section>
      <section className="home__opening_hours">
        <h2>Opening Hours</h2>
        <OpeningHours />
      </section>
      <section className="home__contact">
        <iframe
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2469.9100381717867!2d-1.260933484220999!3d51.75296857967631!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876c6a2fce12f27%3A0xb915c0eb87e0c7cb!2sPlush%20Oxford!5e0!3m2!1sen!2suk!4v1661178718316!5m2!1sen!2suk"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          tabIndex={-1}
        />
        <aside>
          <h2>Contact Us</h2>
          <p>01865 247966</p>
          <p>
            <a className="animated_link" href="mailto:director@plushoxford.com">
              director@plushoxford.com
            </a>
          </p>
          <p>Plush Oxford, Frewin Court, Oxford OX1 3HZ, United Kingdom</p>
        </aside>
      </section>
    </>
  );
}
